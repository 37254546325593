import axios from "axios";
import {
  login,
  getmess,
  searchDoc
} from "../../api/index";
import {
  api,
  config
} from "../../utils/urllist"
import {
  Alert
} from "element-ui";
// import isMobile from "../../utils/isMobile";
let sUserAgent = navigator.userAgent.toLowerCase();
let bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
let bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
let bIsMidp = sUserAgent.match(/midp/i) == "midp";
let bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
let bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
let bIsAndroid = sUserAgent.match(/android/i) == "android";
let bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
let bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
let isMobile = bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM
export default {
  data() {
    var checkName = (rule, value, callback) => {
      let phtest = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
      if (!value) {
        return callback(new Error("请填入手机号码"));
      } else if (!phtest.test(value)) {
        return callback(new Error("请填入正确的手机号码"));
      } else if (value.toString().length !== 11) {
        return callback(new Error("电话号码必须是11位数字"));
      } else {
        callback();
      }
    };
    var checkYzm = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      loginload:false,
      ruleForm: {
        name: "",
        verification: "", //绑定输入验证码框框
      },
      ruleFormbyPh: {
        name: "",
        verification: "", //绑定输入验证码框框
      },
      // logining: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      rules: {
        verification: [{
          validator: checkYzm,
          trigger: "blur"
        }],
        name: [{
          validator: checkName,
          trigger: "blur"
        }],
      },
      rules2: {
        verification: [{
          validator: checkYzm,
          trigger: "blur"
        }],
        name: [{
          validator: checkName,
          trigger: "blur"
        }],
      },
    };
  },
  mounted() {
    if(!isMobile){
      this.$message.error("请通过手机微信浏览器访问该页面")
      this.loginload=true
    }
  },
  methods: {
    toregist() {
      this.$router.push("/register");
    },
    //  验证
    async getAuthCode(formName) {
      console.log(this.$refs[formName]);
      this.$refs[formName].validateField("name", async (nameErro) => {
        if (nameErro) {
          return false;
        } else {
          let params = {
            phone: this.$refs[formName].model.name,
          };
          const result = await getmess(this.$refs[formName].model.name);
          if (result.msg === "您的手机号码，不是医生") {
            this.$message.error("您的手机号码，不是医生");
          } else {
            this.$message.success("发送成功");
            this.sendAuthCode = false;
            //设置倒计时秒
            this.auth_time = 60;
            var auth_timetimer = setInterval(() => {
              this.auth_time--;
              if (this.auth_time <= 0) {
                this.sendAuthCode = true;
                clearInterval(auth_timetimer);
              }
            }, 1000);
          }
        }
      });
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.logining = true;
          let fdata = new FormData();
          fdata.append("phone", this.$refs[formName].model.name);
          fdata.append("yzm", this.$refs[formName].model.verification);
          fdata.append("type", 2);
          axios
            .post(
              api.urlHead + api.loginSubmit,
              fdata,
              config
            )
            .then(async (res) => {
              console.log(res);
              let result = res.data;
              if (result.msg === "验证码错误") {
                this.$message.error({
                  message: result.msg,
                  duration: 1000,
                });
              } else {
                this.$message({
                  showClose: true,
                  type: "success",
                  message: "登录成功",
                  duration: 1000,
                });
                let getdocdata = {
                  phone: this.$refs[formName].model.name
                }
                sessionStorage.setItem("phone", this.$refs[formName].model.name)
                let databyph = {
                  phone: this.$refs[formName].model.name
                }
                const userinfo = await searchDoc(databyph)
                console.log("userinfo:", userinfo);
                if (userinfo.code === 200) {
                  console.log("userinfo:", userinfo);
                  let userdata = {
                    name: userinfo.data.records[0].name,
                    nickName: userinfo.data.records[0].nickName,
                    headImgUrl: userinfo.data.records[0].headImgUrl,
                    phone: userinfo.data.records[0].phone,
                    isRealName: userinfo.data.records[0].isRealName,
                    agoraUid: userinfo.data.records[0].agoraUid,
                  }
                  sessionStorage.setItem("userinfo", JSON.stringify(userdata));
                }
                let sesurl = JSON.parse(sessionStorage.getItem("urlAll"))
                console.log("sesurl:", sesurl);
                if (!sesurl || sesurl[0].path === "/login") {
                  this.$router.push('/')
                } else {
                  this.$router.push({
                    path: sesurl[0].path,
                    query: sesurl[0].query
                  });
                }

              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("submit err");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};